/* eslint-disable */
import $ from 'jquery';
import Swiper from 'swiper/swiper-bundle.mjs';

window.$ = $;
window.jQuery = $;

// slider
$(() => {
    new Swiper(".project__slider .swiper", {
        pagination: {
            el: ".project__slider .swiper-pagination",
            clickable: true
        },
        navigation: {
            nextEl: ".project__slider .swiper-button-next",
            prevEl: ".project__slider .swiper-button-prev",
        },
    });
});